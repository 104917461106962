import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import imagemCasamento from "./images/imagem-casamento-att.jpg";
import Map from "./components/Map";
import ConfirmPresence from "./components/ConfirmPresence";
import GiftsSection from "./components/GiftsSection";
import FlipClockCountDown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Header from "./components/Header";
import Music from "./components/Music";
import RoupasHomens from "./images/roupa-masculina.jpg"
import RoupasMulheres from "./images/roupa-feminina.jpg"
function App() {
  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="main-photo-container">
          <img src={imagemCasamento} className="main-photo" alt="main-photo" />
        </div>
        <div className="title-container">
          <h1>
            <span className="title">Jéssica e Alexandre</span>
          </h1>
          <div className="wed-info">
            <span className="date">14 DE DEZEMBRO DE 2024</span>
          </div>
        </div>
      </div>
      <div className="countdown">
        <h2 className="secondary-title">Contagem Regressiva para o grande dia</h2>
        <FlipClockCountDown
          id="flipper"
          to={new Date("2024-12-14T13:00:00").getTime() + 24 * 3600 * 1000}
        />
      </div>
      <div className="cerimony-details">
        <div className="cerimony-info">
          <h2 className="secondary-title">Bem Vindos ao início da nossa história!</h2>
          <div className="bride-comment-title">
            <h3>Por Jéssica</h3>
          </div>
          <p className="bride-comment">
            Eu e o Alexandre temos alguns bons anos juntos. Nos conhecemos no
            Colégio Farias Brito em 2014, já estudávamos na mesma turma desde o
            8º ano, mas por algum motivo "decidimos" nos conhecer justo no
            pré-vestibular, o melhor ano da vida escolar para iniciar um
            relacionamento (contém ironia).
          </p>
          <p className="bride-comment">
            Mesmo estudando na mesma sala há anos nunca havia falado com o
            Alexandre, no máximo em algum dos eventos do colégio que tínhamos
            que participar e eu falava com ele sobre alguma montagem ou
            necessidade para o projeto da turma. Mas no terceiro ano acabamos
            sentando próximos na sala devido nossas amizades em comum e
            conversando mais. Depois de conhecer ele um pouco descobrimos muitas
            coisas em comum e por minha parte começou a surgir certo interesse,
            mas o Alexandre sempre foi muito fechado e era difícil saber o que
            se passava na mente dele (até hoje na verdade). Então comecei a
            implicar com ele para chamar atenção, especialmente brincando com um
            estojo que ele tinha na época e fomos nos aproximando cada vez mais.
          </p>
          <p className="bride-comment">
            Minhas amigas já sabiam do meu interesse no Alexandre e acabaram
            tramando uma história em que caíram tanto eu como ele. Pouco antes
            de uma prova elas vieram até mim falando que após a prova ia todo
            mundo para o cinema do Shopping Benfica e perguntaram se eu não iria
            também. Falei que poderia dar certo, e então elas falaram: - E
            porque você não aproveita para chamar o Alexandre? E então falei: -É
            verdade, porque não? (Logo o Alexandre, maior furão de todos os
            tempos). Fui lá e chamei ele, ele respondeu com um "pode ser".
          </p>
          <p className="bride-comment">
            Ao sair da prova e ligar meu celular me surpreendo com chamadas não
            atendidas do meu pai e já bateu o nervoso, não avisei meu pai que
            iria sair e ele está vindo me buscar (meu pai na época vinha me
            buscar de moto e não era bem a pessoa mais paciente quando haviam
            mudanças de planos). Quando retorno a ligação ele atende com um
            'cheguei', já fiquei pensando que não ia dar certo e fui até ele lá
            fora do colégio. Cheguei para falar com ele sobre o cinema que tinha
            conversado com minhas amigas já esperando pelo "não", mas para minha
            surpresa ele falou: - Tudo bem, quer que eu te deixe no Shopping? e
            maior supresa ainda quando ele me deixou lá e falou: - Tem dinheiro
            para comer algo? Toma 20 reais. MEU DEUS.
          </p>
          <p className="bride-comment">
            Ao chegar no shopping tenho minha segunda surpresa ao perceber que
            não ia "todo mundo" para lá, só estavam uma colega com um namorado
            dela da época e eu, até então nem sinal do Alexandre e nem eu -nem
            ninguém- tinha o número dele. Decidimos os 3 que estavam lá por
            assistir um filme nacional que se iniciaria às 20h e como o
            Alexandre não havia chegado eu - sem saber se ele realmente viria -
            comprei o ingresso dele também e ficamos sentados ali próximo ao
            cinema esperando dar o horário do filme.
          </p>
          <p className="bride-comment">
            Chegando o horário nada do Alexandre aparecer e o casal que estava
            comigo decidiu entrar na sala. Eu já nervosa, decidi andar pelo
            shopping para desestressar e fui descer pela escada normal que dá do
            andar do cinema para o primeiro andar e minha surpresa quando olho
            para o final da escada e estava o Alexandre se peparando para subir
            a escada - como eu gosto de ser protagonista da minha própria
            história, já veio aquela imagem de filme dos dois se encontrando na
            escada - e foi isso mesmo. Após nosso encontro fomos nas Americanas
            para comprar uns chocolates para comer no filme e entramos na
            sessão.
          </p>
          <p className="bride-comment">
            Sim, demos o nosso primeiro beijo durante o filme "Muita Calma Nessa
            Hora 2" no dia 23 de fevereiro de 2014 e até comentamos que achamos
            o filme legalzinho no final. No dia seguinte ficou aquela indecisão
            sobre o que a gente tinha, se é que tinhamos algo e na aula mandei
            uma cartinha justamente com essa pergunta e ele devolveu com um
            papel escrito -Não estamos namorando? E então nossa história juntos
            começou com esse pedido de namoro inusitado.
          </p>
          <p className="bride-comment">
            A partir daí vivemos mais de 10 anos de um relacionamento muito
            tranquilo sendo namorados e também melhores amigos. Estudamos juntos
            para o vestibular, vivemos as felicidades e angústias da época da
            faculdade e do primeiro emprego, procuramos nosso primeiro
            apartamento, mobiliamos juntos, adotamos nossa filha felina Jubi,
            noivamos e planejamos com muito carinho cada parte do nosso
            casamento.
          </p>
        </div>
        <div className="cerimony-info">
          <h2 className="secondary-title" id="LOCAL">Local</h2>
          <p>
            A cerimônia e festa ocorrerão no mesmo local, serão realizados no
            sítio dos Pais da Noiva, no endereço Rua Estrada do Jucurutu, 577
            (Casa 18) - Loteamento Novo Aquiraz, Aquiraz.{" "}
          </p>
          <Map />
        </div>
        <div className="cerimony-info">
          <h2 className="secondary-title">Vestimentas</h2>
          <p className="dress-paragraph">
            O traje será Esporte Fino, mas como nosso casamento ocorrerá em um
            sítio com início num fim de tarde não podemos abrir mão do conforto
            também. Estamos deixando algumas recomendações para ajudar com a
            escolha no dia:
          </p>
          <ul>
            <div className="dress">
              <li className="dress-paragraph">
                Para as mulheres: Indicamos vestidos longos com tecidos fluidos ou
                que não esquentem muito. Sobre as cores indicamos estampas
                florais, tons coloridos e que realcem sua beleza, mas pedimos que
                se possível evitem tons muito próximos ao branco e os tons de rosa
                Pink e Fúcsia da paleta das madrinhas. Sobre o que usar nos pés não
                indicamos saltos muito altos e muito menos saltos finos, no sítio
                temos áreas mais firmes para andar, mas também temos áreas com
                areia e grama. Saltos médios ou rasteirinhas elegantes são opções
                ideais para proporcionar conforto.
              </li>
              <img src={RoupasMulheres} className="dress-img"/>
            </div>
            <div className="dress">
              <li className="dress-paragraph">
                Para os homens: O ambiente do sítio oferece oportunidade de ter um
                traje mais simples, o combo calça social em linho ou sarja com a
                blusa de botão nunca dá errado. Blazers ou coletes leves adicionam
                um toque mais arrumado sem serem muito formais. Caso opte por
                vir com um traje mais formal pedimos apenas que evitem o tom de
                cinza claro do terno dos padrinhos e a gravata em tom rosa.
              </li>
              <img src={RoupasHomens} className="dress-img"/>
            </div>
          </ul>
        </div>
        <div className="gifts-section">
          <h2 className="secondary-title" id="LISTADEPRESENTES"> Lista de Presentes</h2>
          <GiftsSection />
        </div>
        <div className="confirm-presence">
          <h2 className="secondary-title" id="CONFIRMARPRESENCA">Confirmar presença</h2>
          <ConfirmPresence />
        </div>
      </div>
      <div>
        <Music />
      </div>
    </div>
  );
}

export default App;
